import { redux } from '@ohif/core';

const { toggleLabels } = redux.actions;

/**
 * Enable labels
 *
 * @return void
 */
const setToggleLabels = () => {
  const action = toggleLabels();

  window.store.dispatch(action);
};

export default setToggleLabels;
